import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../components/layout-solution"
import Seo from "../components/seo"

//components
import Cases from "../components/cases"
import Modal from "../components/modal/quickbase-modal"
import PartnerSwiper from "../components/swiper/partners.js"

import { StaticImage } from "gatsby-plugin-image"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

import Hero from "../components/hero/hero-quickbase-system-integration"
import ExitIntentModal from "../components/modal/exit-intent-email"

class SystemIntegrationConsultingPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description
    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        banner={<Hero />}
      >
        <Seo title={siteTitle} description={siteDescription} />
        <div id="main">
          <LazyLoadComponent>
            <section
              id="quandary-help-wrapper"
              className="quandary-help-wrapper mt-5"
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <h2 className="font-weight-medium">
                      ORGANIZATIONS <span className="text-green">TRUST</span>{" "}
                      QUANDARY CONSULTING GROUP
                    </h2>
                  </div>
                </div>
                <LazyLoadComponent>
                  <PartnerSwiper />
                </LazyLoadComponent>
              </div>
            </section>
            <ExitIntentModal />

            <section
              className="quickbase-support"
              style={{ padding: "5rem 0" }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 mx-auto text-center">
                    <h1>“NO FUSS” INTEGRATION SUPPORT </h1>
                    <p>
                      Outsource system integration stress and frustration to
                      integration specialists who will help you compete with
                      enterprise organizations. Without the enterprise price
                      tag.
                    </p>
                    <div className="pt-5">
                      <Modal />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="quickbase-integrations">
              <div className="container quickbase-integrations-padding">
                <div className="row">
                  <div className="col-lg-12 text-center pt-5">
                    <h3 className="text-white mt-4">
                      TEAM UP WITH EXPERTS IN SYSTEMS INTEGRATION
                    </h3>
                    <p className="text-white">
                      Bridging the gaps between systems can be a nightmare. We
                      help you avoid roadblocks, slowdowns, and costly mistakes.
                    </p>
                  </div>

                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/coding-icon.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold">
                      ENORMOUSLY RESPONSIVE
                    </p>
                    <p className="pt-2 text-white">
                      Get your questions answered and your concerns addressed in
                      hours (not days or weeks).
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/Drag-Icon.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold">
                      OWN YOUR CODE
                    </p>
                    <p className="pt-2 text-white">
                      We don’t hold your code hostage. We build the apps. You
                      control the code.
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/dashboard-icons.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold">
                      MORE TIME
                    </p>
                    <p className="pt-2 text-white">
                      Delegate the heavy lifting to us and get more time to
                      focus on your core business concerns.
                    </p>
                  </div>
                </div>
                <div className="row pt-5">
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/phone-icon.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold">
                      EFFORTLESS ONBOARDING
                    </p>
                    <p className="pt-2 text-white">
                      We train your team on new systems directly to ensure
                      adoption and success.
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/automate-icon.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold">
                      DYNAMIC REPORTING
                    </p>
                    <p className="pt-2 text-white">
                      Get the exact reporting you envisioned with an accessible
                      dashboard for clear insights.
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/roi-icon.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold">
                      ENHANCED ROI
                    </p>
                    <p className="pt-2 text-white">
                      We uncover major inefficiencies through extensive
                      discovery and build custom solutions.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section className="what-to-expect" style={{ padding: "5rem 0" }}>
              <div className="container pb-5">
                <div className="row">
                  <div className="col-lg-12 col-md-8 mx-auto text-center">
                    <h2 className="font-weight-medium">
                      INTEGRATION SUPPORT: BETTER. FASTER. EASIER.
                    </h2>
                    <p className="mt-3">
                      As your integration specialists, we use our expertise and
                      experience to help break down your bottlenecks and
                      transform your business processes.
                    </p>
                  </div>
                </div>
                <div className="row" style={{ padding: "65px 0px 0px 0px" }}>
                  <div className="col mb-4 mx-5 what-to-expext-box text-center">
                    <p className="text-green pt-3 font-weight-bold">
                      WORKFLOW MAPPING
                    </p>
                    <p className="my-3 pb-3">
                      We analyze your workflows FIRST, then build
                      <br />
                      the solutions you really need.
                    </p>
                  </div>
                  <div className="col mb-4 mx-5 what-to-expext-box text-center">
                    <p className="text-green pt-3 font-weight-bold">
                      UNMATCHED EXPERIENCE
                    </p>
                    <p className="my-3 pb-3">
                      We’ve built a range of applications and automations <br />
                      from scratch for businesses large and small.
                    </p>
                  </div>
                  <div className="col mb-4 mx-5 what-to-expext-box text-center">
                    <p className="text-green pt-3 font-weight-bold">
                      CONTINUOUS PROCESS IMPROVEMENT
                    </p>
                    <p className="my-3 pb-3">
                      We create a custom roadmap designed to streamline
                      <br /> automation and provide the highest ROI.
                    </p>
                  </div>
                </div>
                <div className="col-lg-8 mx-auto text-center pt-5">
                  <Modal />
                </div>
              </div>
            </section>

            <section
              id="it-consulting"
              className="it-consulting"
              style={{ backgroundColor: "whitesmoke" }}
            >
              <div className="container-fluid px-5">
                <div className="row">
                  <div className="col text-center mt-5 pb-5 px-5 mx-3">
                    <div className="pb-3">
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                    </div>
                    <h3 className="text-green">
                      Awesome Collaborative Partners
                    </h3>
                    <p className="my-4" style={{ minHeight: "5rem" }}>
                      "We have been working with Quandary for a year or two now.
                      They are great to work with and are very collaborative in
                      working with you to develop tools that will work best for
                      your organization. The development process from the
                      customer perspective is always simple and easy. The team
                      at Quandary is always very responsive to our needs, and
                      goes above and beyond to ensure that we are satisfied
                      customers."
                    </p>
                    <div className="py-3">
                      <p>- Thomas Arlow, Dexko</p>
                    </div>
                  </div>

                  <div className="col text-center mt-5 pb-5 px-5 mx-3">
                    <div className="pb-3">
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                    </div>
                    <h3 className="text-green">Quick Start Program</h3>
                    <p className="my-4" style={{ minHeight: "5rem" }}>
                      "Alex and the Quandary team were outstanding. They
                      provided exceptional insight into solving various design
                      issues, coached us on some of the "tips and tricks" when
                      working in the environment, and accommodated our shifting
                      calendar and development priorities. They are sharp,
                      knowledgeable, and easy to work with. HIGHLY recommended
                      for anyone entering the Quickbase world."
                    </p>
                    <div className="py-3">
                      <p>- Jim Carpenter, Flexible Business Systems</p>
                    </div>
                  </div>
                  <div className="col text-center mt-5 pb-5 px-5 mx-3">
                    <div className="pb-3">
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                    </div>
                    <h3 className="text-green">THAT WAS QUICK...</h3>
                    <p className="my-4" style={{ minHeight: "5rem" }}>
                      "Finding a vendor that not only responds in a timely
                      manner but also proactively identifies potential
                      roadblocks is like finding a needle in a hay stack. They
                      diagnosed problems with surgical precision and with the
                      efficiency of an energy star appliance. We look forward to
                      working Quandary again in the future. If you have a tech
                      project that could use some help, be sure to give Quandary
                      a call."
                    </p>
                    <div className="py-3">
                      <p>
                        - Ryan, Loop Media, Inc.
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="case-studies-container">
              <div id="case-studies" className="bg-indigo">
                <div className="container-fluid">
                  <div className="intro text-center">
                    <div className="row">
                      <div className="col-md-10 mx-auto">
                        <p
                          className="h5 text-light-blue text-uppercase font-weight-bold m-0"
                          style={{ fontFamily: "Futura,sans-serif" }}
                        >
                          Case Studies
                        </p>
                        <h2 className=" my-2 text-white">
                          We Save Clients 1000s of Hours. Every Year.
                        </h2>
                        {/* <p className="text-white">
                          We helped a national healthcare provider save over
                          9,000 hours in weekly labor costs by developing a
                          single application...
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="">
                  <Cases
                    allSanityCaseStudy={this.props.data.allSanityCaseStudy}
                  />
                  <div className="text-center">
                    <Link
                      className="btn btn-primary btn-arrow mr-2 mt-4 text-center"
                      to="/case-studies/"
                    >
                      More Case Studies
                    </Link>
                  </div>
                </div>
              </div>
            </section>

            <section id="case-studies-container">
              <div id="case-studies">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col text-center">
                      <h2 className="my-2 font-weight-medium">
                        FINALLY.{" "}
                        <span className="text-green">
                          A BETTER WAY TO BRIDGE GAPS BETWEEN SYSTEMS.
                        </span>
                      </h2>
                      <p>
                        Don’t waste your time struggling to connect your
                        application stack. Delegate the heavy
                        <br /> lifting to your personal integrations partner.
                      </p>
                      <div className="col-lg-5 mx-auto text-center pt-3 ">
                        <Modal />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </LazyLoadComponent>
        </div>
      </Layout>
    )
  }
}

export default SystemIntegrationConsultingPage

export const SystemIntegrationConsultingPageQuery = graphql`
  query SystemIntegrationConsultingPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
